console.log(
	'%c Welcome to boilerplate-webpack by Mythology Labs! ',
	'background: #21557d; border: 1px solid #fff; padding: 4px; padding-top: 10px; padding-bottom: 8px;'
);
console.log(
	'%c Created by https://tris.codes 💠 improved by http://mythologylabs.com.uy',
	'background: #e4b349; border: 1px solid #000; padding: 4px; padding-top: 10px; padding-bottom: 8px; color:#21557d;'
);

// add custom js below
document.addEventListener('DOMContentLoaded', () => {
	"use strict";

	const button = document.querySelector("#btnPlatform");
	RemoveColorFromPlatformButton();

	/**
	 * Preloader
	 */
	const preloader = document.querySelector('#preloader');
	if (preloader) {
		window.addEventListener('load', () => {
			preloader.remove();
		});
	}

	/**
	 * Sticky header on scroll
	 */
	const selectHeader = document.querySelector('#header');
	const logo = document.querySelector('#logo');
	const nodeList = document.querySelectorAll('.navbar li > a');
	if (selectHeader) {
		document.addEventListener('scroll', () => {
			if(window.scrollY > 0){
				selectHeader.classList.add('sticked');
				logo.classList.add('logo-color');
				logo.classList.remove('logo');
        nodeList.forEach((item) => item.classList.contains('active') ? item.style.color = "#F26516" : item.style.color = "#131313")
				AddColorFromPlatformButton();
			} else {
				selectHeader.classList.remove('sticked');
				logo.classList.add('logo');
				logo.classList.remove('logo-color');
        nodeList.forEach((item) => item.style.color = "white")
				RemoveColorFromPlatformButton();
			}
		});
	}
	
  //The number 100 is because of the height of the navbar
  function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 100 || 
        (rect.top < 100 && rect.bottom >= 100)
    );
  }

	let nav_sections = document.querySelectorAll('section');
  let allChildren = document.querySelectorAll(".navbar li a");
  
  if (nav_sections) {
    const toggleNavSections = () => {
      for (let i = 0; i < nav_sections.length; i++) {
        let menuOption = nav_sections[i];
        if (isInViewport(menuOption)){
          allChildren.forEach((item) => item.href.includes(menuOption.id) ? item.classList.add('active') : item.classList.remove('active'));
          break;
        }
      }
    }
    window.addEventListener('load', toggleNavSections)
    document.addEventListener('scroll', toggleNavSections)
  }

	logo.addEventListener('click', function(event){
		for (let i = 0; i < nodeList.length; i++) {
			nodeList[i].classList.remove("active");
		}
	})

	const menuClients = document.querySelector('#menu-clients');
	if(menuClients){
		menuClients.addEventListener('click', function(event){
			ActiveMenuOption(menuClients);
		});
	}

	const menuFeatures = document.querySelector('#menu-features');
	if(menuFeatures){
		menuFeatures.addEventListener('click', function(event){
			ActiveMenuOption(menuFeatures);
		});
	}

	const menuHowItWorks = document.querySelector('#menu-how-it-works');
	if(menuHowItWorks){
		menuHowItWorks.addEventListener('click', function(event){
			ActiveMenuOption(menuHowItWorks);
		});
	}

	const menuServices = document.querySelector('#menu-services');
	if(menuServices){
		menuServices.addEventListener('click', function(event){
			ActiveMenuOption(menuServices);
		});
	}

	const menuPlans = document.querySelector('#menu-plans');
	if(menuPlans){
		menuPlans.addEventListener('click', function(event){
			ActiveMenuOption(menuPlans);
		});
	}

	const menuJoinUs = document.querySelector('#menu-join-us');
	if(menuJoinUs){
		menuJoinUs.addEventListener('click', function(event){
			ActiveMenuOption(menuJoinUs);
		});
	}

	const menuContact = document.querySelector('#menu-contact');
	if(menuContact){
		menuContact.addEventListener('click', function(event){
			ActiveMenuOption(menuContact);
		});
	}

	/**
	 * Mobile nav toggle
	 */
	// const mobileNavShow = document.querySelector('.mobile-nav-show');
	// const mobileNavHide = document.querySelector('.mobile-nav-hide');

	// document.querySelectorAll('.mobile-nav-toggle').forEach(el => {
	// 	el.addEventListener('click', function(event) {
	// 		event.preventDefault();
	// 		mobileNavToogle();
	// 	})
	// });

	// function mobileNavToogle() {
	// 	document.querySelector('body').classList.toggle('mobile-nav-active');
	// 	mobileNavShow.classList.toggle('d-none');
	// 	mobileNavHide.classList.toggle('d-none');
	// }

	/**
	 * Toggle mobile nav dropdowns
	 */
	// const navDropdowns = document.querySelectorAll('.navbar .dropdown > a');

	// navDropdowns.forEach(el => {
	// 	el.addEventListener('click', function(event) {
	// 		if (document.querySelector('.mobile-nav-active')) {
	// 			event.preventDefault();
	// 			this.classList.toggle('active');
	// 			this.nextElementSibling.classList.toggle('dropdown-active');

	// 			let dropDownIndicator = this.querySelector('.dropdown-indicator');
	// 			dropDownIndicator.classList.toggle('bi-chevron-up');
	// 			dropDownIndicator.classList.toggle('bi-chevron-down');
	// 		}
	// 	})
	// });

	/**
	 * Scroll top button
	 */
	const scrollTop = document.querySelector('.scroll-top');
	if (scrollTop) {
		const togglescrollTop = function() {
			window.scrollY > 100 ? scrollTop.classList.add('active') : scrollTop.classList.remove('active');
		}
		window.addEventListener('load', togglescrollTop);
		document.addEventListener('scroll', togglescrollTop);
		scrollTop.addEventListener('click', window.scrollTo({
			top: 0,
			behavior: 'smooth'
		}));
	}

	/**
	 * Porfolio isotope and filter
	 */
	let portfolionIsotope = document.querySelector('.portfolio-isotope');

	if (portfolionIsotope) {

		let portfolioFilter = portfolionIsotope.getAttribute('data-portfolio-filter') ? portfolionIsotope.getAttribute('data-portfolio-filter') : '*';
		let portfolioLayout = portfolionIsotope.getAttribute('data-portfolio-layout') ? portfolionIsotope.getAttribute('data-portfolio-layout') : 'masonry';
		let portfolioSort = portfolionIsotope.getAttribute('data-portfolio-sort') ? portfolionIsotope.getAttribute('data-portfolio-sort') : 'original-order';

		window.addEventListener('load', () => {
			let portfolioIsotope = new Isotope(document.querySelector('.portfolio-container'), {
				itemSelector: '.portfolio-item',
				layoutMode: portfolioLayout,
				filter: portfolioFilter,
				sortBy: portfolioSort
			});

			let menuFilters = document.querySelectorAll('.portfolio-isotope .portfolio-flters li');
			menuFilters.forEach(function(el) {
				el.addEventListener('click', function() {
					document.querySelector('.portfolio-isotope .portfolio-flters .filter-active').classList.remove('filter-active');
					this.classList.add('filter-active');
					portfolioIsotope.arrange({
						filter: this.getAttribute('data-filter')
					});
					/*if (typeof aos_init === 'function') {
						aos_init();
					}*/
				}, false);
			});

		});
	}

	/**
	 * Private methods
	 */
	function AddColorToActiveMenuOptions(){
		for (let i = 0; i < nodeList.length; i++) {
			let menuOption = nodeList[i];
			menuOption.style.color = "#032974";
			if(menuOption.classList.contains("active")){
				menuOption.style.color = "#e64b23";
			}
		}
	}

	function AddColorFromPlatformButton(){
		button.classList.add('btn-outline-dark');
		button.classList.remove('btn-outline-light');
		button.style.borderColor = '#032974';
		button.style.color = "#032974";
		button.addEventListener('mouseenter', () => {
			button.style.backgroundColor = '#032974';
			button.style.color = "white";
		})
		button.addEventListener('mouseleave', () => {
			button.style.backgroundColor = '';
			button.style.color = "#032974";
		})
	}

	function RemoveColorFromActiveMenuOptions(){
		for (let i = 0; i < nodeList.length; i++) {
			let menuOption = nodeList[i];
			menuOption.style.color = "rgba(255, 255, 255, 0.6)";
			menuOption.classList.remove("active");
		}
	}
	
	function RemoveColorFromPlatformButton(){
		button.classList.remove('btn-outline-dark');
		button.classList.add('btn-outline-light');
		button.style.borderColor = 'white';
		button.style.color = "white";
		button.addEventListener('mouseenter', () => {
			button.style.backgroundColor = '#032974';
		})
		button.addEventListener('mouseleave', () => {
			button.style.backgroundColor = '';
			button.style.color = "white";
		})
	}

	function ActiveMenuOption(menuOption){
		for (let i = 0; i < nodeList.length; i++) {
			nodeList[i].classList.remove("active");
		}
		menuOption.classList.add("active");
	}	
});