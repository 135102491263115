import * as OfflinePluginRuntime from 'offline-plugin/runtime';
import Swiper from '../node_modules/swiper/swiper-bundle.js';
import AOS from 'aos';
import GLightbox from "glightbox";

import "../node_modules/@fortawesome/fontawesome-free/js/all.min.js";

import './fonts/libre-baskerville-v5-latin-regular.woff';
import './fonts/libre-baskerville-v5-latin-regular.woff2';
import './fonts/icons.woff';

import './index.scss';
import './scripts/script';

AOS.init({
		duration: 400,
		easing: 'ease',
		once: true,
		mirror: false,
		disable: 'mobile'
	});

/**
* Initiate glightbox
*/
const glightbox = GLightbox({
	selector: '.glightbox'
});

/**
* Init clients slider
*/
const slide = new Swiper('.swiper-container', {
	speed: 600,
	preloadImages: false,
	lazy: true,
	loop: true,
	autoplay: {
	  delay: 5000,
	  disableOnInteraction: false
	},
	pagination: {
	  el: '.swiper-pagination',
	  type: 'bullets',
	  clickable: true
	},
	navigation: {
	  nextEl: '.swiper-button-next',
	  prevEl: '.swiper-button-prev',
	},
	centeredSlides: true,
	breakpoints: {
	  320: {
		slidesPerView: 1,
		spaceBetween: 1
	  },
	  600: {
		slidesPerView: 3,
		spaceBetween: 1
	  },
	  1200: {
		slidesPerView: 5,
		spaceBetween: 1
	  }
	}
});

/**
* Init clients slider
*/
var servicesSwiper = new Swiper('.services-slider', {
	speed: 600,
	preloadImages: false,
	lazy: true,
	loop: true,
	autoplay: {
	  delay: 5000,
	  disableOnInteraction: false
	},
	spaceBetween: 30,
	effect: 'fade',
	breakpoints: {
	  992: {
		slidesPerView: 1,
		spaceBetween: 1
	  }
	}
});

document.getElementById('slide-sanitaria').addEventListener('click', function(){
	servicesSwiper.slideTo(1);
})

document.getElementById('slide-aire').addEventListener('click', function(){
	servicesSwiper.slideTo(2);
})

document.getElementById('slide-carpinteria').addEventListener('click', function(){
	servicesSwiper.slideTo(3);
})

document.getElementById('slide-jardineria').addEventListener('click', function(){
	servicesSwiper.slideTo(4);
})

document.getElementById('slide-electricista').addEventListener('click', function(){
	servicesSwiper.slideTo(5);
})

document.getElementById('slide-fumigacion').addEventListener('click', function(){
	servicesSwiper.slideTo(6);
})

document.getElementById('slide-mudanza').addEventListener('click', function(){
	servicesSwiper.slideTo(7);
})

document.getElementById('slide-herramientas').addEventListener('click', function(){
	servicesSwiper.slideTo(8);
})

document.getElementById('slide-vidrieria').addEventListener('click', function(){
	servicesSwiper.slideTo(9);
})

document.getElementById('slide-cerrajeria').addEventListener('click', function(){
	servicesSwiper.slideTo(10);
})

document.getElementById('slide-painter').addEventListener('click', function(){
	servicesSwiper.slideTo(11);
})

document.getElementById('slide-red').addEventListener('click', function(){
	servicesSwiper.slideTo(0);
})

const businessType = document.getElementById('business-type');

if(businessType){
	const words = businessType.children;
	var displayed = 0;
	if (words.length > 0){
		setInterval(() => {
			let element = words[displayed];
			element.setAttribute('class', 'is-hidden');
			setTimeout(() => {
				element.setAttribute('class', 'd-none');
				
				displayed += 1;
				if(displayed >= words.length) {
					displayed = 0;
				}
				
				let nextToDisplay = words[displayed];
				nextToDisplay.setAttribute('class', 'is-visible d-inline-block');
			}, 600);
		}, 2000);
	}
}

OfflinePluginRuntime.install();

let field = document.getElementById("copyright-year");
field.innerHTML = new Date().getFullYear();